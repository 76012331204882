import React, { useState, Fragment } from "react";
import ReactDOM from 'react-dom';
import view from '../featured/featured.module.scss';
import './reportpopupstyle.module.scss';

const ReportPopupSRPRevamp = ({ vin, featured }) => {
    const [showPopup, setShowPopup] = useState(false);
    const reportUrl = `https://rechargeddata.mxssolutions.com/vehicle/${vin}/`;

    return (
        <Fragment>
            {/* Open Report Button */}
            <div href="#" id="reportOpenIframe" style={{ height: "43px" }} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(true); }}>
                <img id={view['reportImg']} src="https://media.dealermasters.com/Recharged/icons/RechargedScoreTransparentSmall.webp" alt="view the recharged report" />
            </div>

            {/* Popup overlay */}
            {(showPopup && featured) ?
                ReactDOM.createPortal(
                    <div id="popupReportOverlay">
                        <div id={`iframeReportContainer-${vin}`} onClick={(e) => e.stopPropagation()}>
                            <span id={`reportCloseButton-${vin}`} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(false); }}>X</span>
                            <iframe src={reportUrl} width="100%" height="100%" frameBorder="0"></iframe>
                        </div>
                    </div>, document.getElementById('report-portal-root')
                )
            : showPopup && !featured ?
                <div id="popupReportOverlay">
                    <div id={`iframeReportContainer-${vin}`} onClick={(e) => e.stopPropagation()}>
                        <span id={`reportCloseButton-${vin}`} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(false); }}>X</span>
                        <iframe src={reportUrl} width="100%" height="100%" frameBorder="0"></iframe>
                    </div>
                </div>
            : null
            }
        </Fragment>
    )
}

export default ReportPopupSRPRevamp;